import React, { useEffect } from "react";
import "../pages/profile.css";
import { Container, Row, Col, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import * as Icon from "react-bootstrap-icons";
import axios from "axios";
import moment from "moment";
const Profile = () => {
  const [Event, setEvent] = React.useState([]);
  const [loader, setloader] = React.useState(false);
  const navigate = useNavigate();
  const [pdf, setPdf] = React.useState();
  const userDetails = useSelector((state) => state?.auth?.userDetails);
  const userid = useSelector((state) => state?.auth?.userDetails?.user_id);
  console.log("userDetails", userDetails);
  const getUserdetails = async () => {
    console.log("userid", userid);
    const eventDetails = await axios.get(
      process.env.REACT_APP_API +
        `/event/list/user-id/${userid}?type=OPEN&page=0`
    );
    console.log("eventDetails", eventDetails);
    setEvent(eventDetails?.data);
  };

  const downloadCert = async (id) => {
    setloader(true);
    const res = await fetch(
      process.env.REACT_APP_API + "/eventpdf/create-pdf",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: userDetails?.user_id,
          event_id: id,
          mobile: userDetails?.mobile,
          first_name: userDetails?.first_name,
          last_name: userDetails?.last_name,
        }),
      }
    )
      .then((res) => res.json())
      .then((respon) => {
        setloader(false);
        console.log("respon.pdf_link", respon.pdf_link);
        if (respon.pdf_link) {
          fetch(respon.pdf_link).then((response) => {
            response.blob().then((blob) => {
              // Creating new object of PDF file
              const fileURL = window.URL.createObjectURL(blob);
              // Setting various property values
              let alink = document.createElement("a");
              alink.href = fileURL;
              alink.download = "sample.pdf";
              alink.click();
            });
          });
        }
        if (res.status === 500 || !res) {
          window.alert("Invalid Registration");
          console.log("Invalid Registration");
        }
      })
      .catch((err) => {
        setloader(false);
        console.log("error", err);
      });
  };
  useEffect(() => {
    getUserdetails();
  }, []);

  return (
    <>
      {userDetails ? (
        <Row>
          <Col md={10}>
            <Card>
              <div className="d-flex align-items-center w-100 header">
                <button
                  onClick={() => {
                    navigate(-1);
                  }}
                  className="btn btn-link text-white pl-0"
                >
                  <i className="las la-arrow-left"></i>
                </button>
                <h2> Profile</h2>
              </div>
              <div className="d-flex justify-content-start align-items-center text-dark mt-5 mb-3">
                <Icon.Person
                  className="me-3"
                  size={25}
                  style={{ color: "#e5851f" }}
                />
                <h4>
                  {" "}
                  <strong>Name :- </strong>{" "}
                </h4>
                <span>
                  <h2>
                    {userDetails?.first_name} {userDetails?.last_name}
                  </h2>
                </span>
              </div>
              <div className="d-flex justify-content-start align-items-center text-dark mb-3">
                <Icon.Phone
                  className="me-3"
                  size={25}
                  style={{ color: "#e5851f" }}
                />
                <h4>
                  {" "}
                  <strong>Mobile Number :- </strong>{" "}
                </h4>
                <span>
                  <h2>{userDetails?.mobile}</h2>
                </span>
              </div>
            </Card>
            {/* <div className="d-flex justify-content-start align-items-center text-dark mt-3 mb-3">
                <Icon.CalendarEventFill
                  className="me-3"
                  size={25}
                  style={{ color: "#e5851f" }}
                />
                <h4 className="w-25">
                  <strong>Registred Event:- </strong>
                </h4>
                <span className="w-75">
                  {Event.length > 0
                    ? Event.map((item, index) => {
                        return (
                          <div>
                            <strong className="mx-2">{index + 1}</strong>
                            <span>{item[0].event_title_mar}</span>
                            {"\n"}
                          </div>
                        );
                      })
                    : "No Event Registred"}
                </span>
              </div> */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "15px",
              }}
            >
              <h2 style={{ color: "#f9c40a" }} className="fw-bolder">
                Registred Event
              </h2>
            </div>
            {Event.length > 0 ? (
              Event.map((item, index) => {
                return (
                  <Card id={index}>
                    <div className="d-flex justify-content-start align-items-center text-dark mt-3 mb-3">
                      <Icon.CalendarEventFill
                        className="me-3"
                        size={25}
                        style={{ color: "#e5851f" }}
                      />
                      <h6>
                        {" "}
                        <strong className="w-50">
                          Registred Event :-{" "}
                        </strong>{" "}
                        <span>{item[0].event_title}</span>
                      </h6>
                    </div>
                    <div className="d-flex justify-content-start align-items-center text-dark  mb-3">
                      <Icon.CalendarDate
                        className="me-3"
                        size={25}
                        style={{ color: "#e5851f" }}
                      />
                      <h6>
                        {" "}
                        <strong className="w-50">Event date :- </strong>{" "}
                        <span>
                          {moment(item[0].event_date).format("DD-MMM-YYYY ")}
                        </span>
                      </h6>
                    </div>
                    <div className="d-flex justify-content-start align-items-center text-dark  mb-3">
                      <Icon.Alarm
                        className="me-3"
                        size={25}
                        style={{ color: "#e5851f" }}
                      />
                      <h6>
                        {" "}
                        <strong className="w-50">
                          Event Start time :-{" "}
                        </strong>{" "}
                        <span>{item[0].event_start_time}</span>
                      </h6>
                    </div>
                    <div className="d-flex justify-content-start align-items-center text-dark  mb-3">
                      <Icon.Alarm
                        className="me-3"
                        size={25}
                        style={{ color: "#e5851f" }}
                      />
                      <h6>
                        {" "}
                        <strong className="w-50">
                          Event End time :-{" "}
                        </strong>{" "}
                        <span>{item[0].event_end_time}</span>
                      </h6>
                    </div>
                    <div className="d-flex justify-content-start align-items-center text-dark  mb-3">
                      <Icon.FileEarmarkBinaryFill
                        className="me-3"
                        size={25}
                        style={{ color: "#e5851f" }}
                      />
                      <h6>
                        {" "}
                        {loader ? (
                          <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : (
                          <span>
                            <button
                              className="btn "
                              onClick={() => {
                                downloadCert(item[0].event_id);
                              }}
                            >
                              Download Receipt
                            </button>
                          </span>
                        )}
                      </h6>
                    </div>
                  </Card>
                );
              })
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <h2>No Data Found</h2>
              </div>
            )}
          </Col>
        </Row>
      ) : (
        <div></div>
      )}

      {/* 
    <div className="container" style={{ height:"100%",width:"100%", display:"flex",placeItems:"center",display:"flex",justifyContent:"center"}}>
		<div className="main-body" >
			<div className="row" style={{ height:"100%",width:"100%", display:"flex",placeItems:"center"}} >
				<div className="col-lg-4" style={{padding:0}}>
					<div className="card">

						
						<div className="card-body">
							<div className="d-flex flex-column align-items-center text-center">
								<img src="https://bootdey.com/img/Content/avatar/avatar6.png" alt="Admin" className="rounded-circle p-1 bg-primary" width="110"/>
								<div className="mt-3">
									<h4>John Doe</h4>
									<p className="text-secondary mb-1">Full Stack Developer</p>
									<p className="text-muted font-size-sm">Bay Area, San Francisco, CA</p>
                                    <div style={{padding:"10px"}}>

									<button className="btn btn-primary">Follow</button>
									<button className="btn btn-outline-primary">Message</button>
                                    </div>
								</div>
							</div>
							<hr className="my-4"/>
							
						</div>
					</div>
				</div>
				<div className="col-lg-8" style={{padding:0}}>
					<div className="card" >
						<div className="card-body">
							<div className="row mb-3">
								<div className="col-sm-3">
									<h6 className="mb-0">Full Name</h6>
								</div>
								<div className="col-sm-9 text-secondary">
									<input type="text" className="form-control" value="John Doe"/>
								</div>
							</div>
                            <div className="input-group common mb-4 mt-3">
                                <ul className='list-inline mb-0'>
                                    <li className='list-inline-item mr-3'>
                                        <label className='mb-0'>Gender :</label>
                                    </li>
                                    <li className='list-inline-item mr-3'>
                                        <div className="radio">
                                            <input type="radio" id="male" name="gender" onChange={handleChange} defaultChecked="true" />
                                            <label htmlFor="male" className='mb-0'>Male</label>
                                        </div>
                                    </li>
                                    <li className='list-inline-item mr-3'>
                                        <div className="radio">
                                            <input type="radio" id="female" name="gender" onChange={handleChange} />
                                            <label htmlFor="female" className='mb-0'>Female</label>
                                        </div>
                                    </li>
                                    <li className='list-inline-item mr-3'>
                                        <div className="radio">
                                            <input type="radio" id="other" name="gender" onChange={handleChange} />
                                            <label htmlFor="other" className='mb-0'>Other</label>
                                        </div>
                                    </li>
                                </ul>
                            </div>
							<div className="row mb-3">
								<div className="col-sm-3">
									<h6 className="mb-0">Email</h6>
								</div>
								<div className="col-sm-9 text-secondary">
									<input type="text" className="form-control" value="john@example.com"/>
								</div>
							</div>
					
							<div className="row mb-3">
								<div className="col-sm-3">
									<h6 className="mb-0">Mobile</h6>
								</div>
								<div className="col-sm-9 text-secondary">
									<input type="text" className="form-control" value="(320) 380-4539"/>
								</div>
							</div>
							<div className="row mb-3">
								<div className="col-sm-3">
									<h6 className="mb-0">Address</h6>
								</div>
								<div className="col-sm-9 text-secondary">
									<input type="text" className="form-control" value="Bay Area, San Francisco, CA"/>
								</div>
							</div>
							<div className="row mb-3">
								<div className="col-sm-3">
									<h6 className="mb-0">Pin code</h6>
								</div>
								<div className="col-sm-9 text-secondary">
									<input type="text" className="form-control" placeholder='Enter PinCode'/>
								</div>
							</div>
							<div className="row">
								<div className="col-sm-3"></div>
								<div className="col-sm-9 text-secondary">
									<input type="button" className="btn btn-primary px-4" value="Save Changes"/>
								</div>
							</div>
						</div>
					</div>
				
				</div>
			</div>
		</div>
	</div> */}
    </>
  );
};

export default Profile;
