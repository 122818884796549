import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Scrollbar from "react-scrollbars-custom";
import { Modal } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import dateFormat from "dateformat";
import FloatingFooter from "../components/FloatingFooter";
import EventImage1 from "../assets/images/event-image-1.png";
import EventImage2 from "../assets/images/event-image-2.png";
import Logoimage from "../assets/images/Picture1.jpg";
import { useNavigate } from "react-router-dom";
import img from "../assets/images/Picture1.jpg";
import "../Layout/styles/fontawesome-4.6.3.min.css";
import "../Layout/styles/framework.css";
import "../Layout/styles/layout.css";
import Yadnyalogo from "../assets/images/Yadnyalogo.webp";
import { logout } from "../redux/Action/authaction";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";
import { ToastContainer, toast } from "react-toastify";
import * as Icon from "react-bootstrap-icons";
import Footer from "../components/Footer";

function Home() {
  const [state, setState] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userdetails = useSelector((state) => state.auth?.userDetails);
  console.log("user Details ", userdetails);

  useEffect(() => {
    getAllEvents();
  }, []);

  const getAllEvents = () => {
    axios
      .get("https://goapi.foxberry.link/v1/event/all/list")
      .then((res) => {
        console.log("get all events", res.data.data);
        setState(res.data.data);
      })
      .catch((err) => {
        console.log("error from api", err);
      });
  };
  console.log("state data", state);

  const profile11 = () => {
    navigate("/profile");
  };
  // const showInMapClicked = () => {
  //   window.open(
  //     "https://maps.google.com?q=" +
  //       details.eventvalue.event_latitude +
  //       "," +
  //       details.eventvalue.event_longitude
  //   );
  // };
  const notify = () =>
    toast("Logout Successfully ", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="topbgded">
        <div className="wrapper row1">
          <div
            id="logo"
            className="fl_left"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {Yadnyalogo ? (
              <img src={Yadnyalogo} style={{ color: "white" }} />
            ) : (
              <div
                className="spinner-border text-primary "
                role="status"
                style={{ margin: "300px" }}
              ></div>
            )}
            {/* <img src={Yadnyalogo} style={{ color: "white" }} /> */}
            <div>
              {/* <Icon.List size={25} style={{ color: "white" }} /> */}

              {userdetails ? (
                <ul
                  style={{
                    paddingLeft: "-4rem",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <li className="active">
                    <button onClick={profile11} className="btn mt-3">
                      <Icon.Person size={25} style={{ color: "white" }} />
                      Profile
                    </button>
                  </li>
                  <li className="active">
                    <button
                      onClick={() => {
                        dispatch(logout());
                        notify();
                      }}
                      className="btn mt-3"
                    >
                      <Icon.BoxArrowLeft
                        size={25}
                        style={{ color: "white", marginRight: "6px" }}
                      />
                      Logout
                    </button>
                  </li>
                </ul>
              ) : (
                ""
              )}
            </div>
          </div>
          {/* {userdetails ? (
            <nav id="mainav" className="fl_right">
              <ul className="clear">
                <li className="active">
                  <button
                    onClick={profile11}
                    className="btn mt-3"
                    style={{ backgroundColor: "#6d0c27" }}
                  >
                    Profile
                  </button>
                </li>
                <li className="active">
                  <button
                    onClick={() => {
                      dispatch(logout());
                    }}
                    className="btn mt-3"
                    style={{ backgroundColor: "#6d0c27" }}
                  >
                    Logout
                  </button>
                </li>
              </ul>
            </nav>
          ) : (
            ""
          )} */}
        </div>

        <article
          style={{ paddingTop: "10px" }}
          id="pageintro"
          className="hoc clear"
        >
          <h2 className="heading" style={{ color: "#fff" }}>
            What and Why we Celebrating the Som Yag Yadyna Festival 2023
          </h2>
          <div className="txtwrap">
            <div
              style={{ color: "#fff", lineHeight: "1.6rem ", fontSize: "16px" }}
            >
              With the 6-day Som Yag Yadnya Festival, we hope to achieve two
              goals, propitiate peace, energise and protect the Goan environment
              by destroying undesirable elements. It involves the chanting of
              selected mantras from three Vedas, the ancient sacred texts of
              Hinduism, Rig, Yajur and Sama- creating a sacred fire, believed to
              cleanse and transform the current energy into higher, positive
              vibrations.
            </div>
            {/* <footer>
                <ul className="nospace inline pushright">
                  <li>
                    <a className="btn" href="#">
                      Posuere
                    </a>
                  </li>
                  <li>
                    <a className="btn inverse" href="#">
                      Porttitor
                    </a>
                  </li>
                </ul>
              </footer> */}
          </div>
        </article>
      </div>
      {/* <!-- End Top Background Image Wrapper --> */}

      <div className="wrapper row3">
        <main className="hoc container clear">
          {/* <!-- main body --> */}
          <div className="row mb-5">
            {state.length > 0 &&
              state.map((data, index) => {
                return (
                  <div key={index}>
                    <div className=" col-12 col-md-4 gy-4">
                      <div className="card h-100 ">
                        <div className="card-body">
                          <h5
                            className="card-title"
                            style={{ fontSize: "30px", fontWeight: "bolder" }}
                          >
                            {data.event_title}
                          </h5>
                          <h6 className="card-text">
                            {data.event_description}
                          </h6>
                          <h6 className="eventinfo">Event Date :</h6>
                          <p className="eventinfotime">
                            {moment(data.event_date).format("DD-MMM-YYYY")} to{" "}
                            {moment(data.event_end_date).format("DD-MMM-YYYY")}
                          </p>
                          <h6 className="eventinfo">Event Time :</h6>

                          <p className="eventinfotime">
                            {data.event_start_time} | {data.event_end_time}
                          </p>
                          <button
                            className="btn w-100  mt-5 mb-3"
                            onClick={() => {
                              navigate(
                                "/user/eventdetails/" + `${data.event_id}`
                              );
                            }}
                          >
                            Register
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          {/* <ul
            className="nospace group services m-5 "
            style={{ display: "flex", justifyContent: "center" }}
          >
            {state.length > 0 &&
              state.map((data, index) => {
                return (
                  <>
                    <li className="one_third first m-3  ">
                      <article>
                        <i className="fa fa-clock-o"></i>
                        <h6 className="heading">{data.event_title}</h6>
                        <p>{data.event_description}</p>

                        <p className="eventinfo">Event Date :</p>
                        <p>
                          {moment(data.event_date).format("DD-MMM-YYYY")} to{" "}
                          {moment(data.event_end_date).format("DD-MMM-YYYY")}
                        </p>
                        <p className="eventinfo">Event Time :</p>

                        <p>
                          {data.event_start_time} | {data.event_end_time}
                        </p>

                        <button
                          className="btn mt-3"
                          onClick={() => {
                            navigate(
                              "/user/eventdetails/" + `${data.event_id}`
                            );
                          }}
                        >
                          Register Event
                        </button>
                      </article>
                    </li>
                  </>
                );
              })}
          </ul> */}

          {/* <!-- / main body --> */}
          <div className="clear"></div>
        </main>
      </div>

      {/* <div
        className="wrapper bgded overlay light"
        style={{ backgroundImage: `${img}` }}
      >
        <div className="hoc container clear">
          <div className="group element">
            <article className="one_third first">
              <img src={img} alt="" />
              <div className="txtwrap">
                <h6 className="heading">Faucibus vestibulum</h6>
                <p>
                  Amet ultricies curabitur eget tempus urna ut porta leo ipsum
                  aliquam maximus sagittis erat malesuada [&hellip;]
                </p>
                <footer>
                  <a href="#">Read More</a>
                </footer>
              </div>
            </article>
            <article className="one_third">
              <img src={img} alt="" />
              <div className="txtwrap">
                <h6 className="heading">Metus blandit fringilla</h6>
                <p>
                  Lorem lacinia nec suspendisse lacinia sapien ac eros ornare
                  consequat duis sit amet tortor sapien nam [&hellip;]
                </p>
                <footer>
                  <a href="#">Read More</a>
                </footer>
              </div>
            </article>
            <article className="one_third">
              <img src={img} alt="" />
              <div className="txtwrap">
                <h6 className="heading">Sodales molestie sit</h6>
                <p>
                  Magna quis tempus tortor mattis ut vestibulum justo lorem
                  viverra eget ipsum volutpat dapibus velit [&hellip;]
                </p>
                <footer>
                  <a href="#">Read More</a>
                </footer>
              </div>
            </article>
          </div>

          <div className="clear"></div>
        </div>
      </div> */}

      {/* <div className="wrapper bgded overlay " style={{ background: "#fa6900" }}>
        <div className="hoc container clear eventlist">
          <section className="one_half first">
            <ul className="nospace iconlist">
              <li>
                <a href="#">
                  <i className="icon fa fa-language"></i>
                </a>
                <p className="heading">
                  6.30 AM - 7.00 AM CLICK IN ,DROP YOUR BAG & GRAB TEA
                </p>
              </li>
              <li>
                <a href="#">
                  <i className="icon fa fa-language"></i>
                </a>
                <p className="heading">7.00 AM - 7.45 PM WALK</p>
              </li>
              <li>
                <a href="#">
                  <i className="icon fa fa-language"></i>
                </a>
                <p className="heading">8.00 AM - 9.00 AM YOGA</p>
              </li>
              <li>
                <a href="#">
                  <i className="icon fa fa-language"></i>
                </a>
                <p className="heading">9.15 AM - 9.30 AM MEDITATION</p>
              </li>
              <li>
                <a href="#">
                  <i className="icon fa fa-language"></i>
                </a>
                <p className="heading">9.15 AM - 9.30 AM MEDITATION</p>
              </li>
            </ul>
          </section>
          <section className="one_half first">
            <ul className="nospace iconlist">
              <li>
                <a href="#">
                  <i className="icon fa fa-language"></i>
                </a>
                <p className="heading">9.30 AM - 10.00 AM PRASADAM</p>
              </li>
              <li>
                <a href="#">
                  <i className="icon fa fa-language"></i>
                </a>
                <p className="heading">10.00 AM - 6.00 AM YADNYA</p>
              </li>
              <li>
                <a href="#">
                  <i className="icon fa fa-language"></i>
                </a>
                <p className="heading">12.30 AM - 1.00 AM LUNCH</p>
              </li>
              <li>
                <a href="#">
                  <i className="icon fa fa-language"></i>
                </a>
                <p className="heading">
                  6.15 AM - 7.00 AM EVENING MEDITATION (SUNSET AROUND 6.20 PM)
                </p>
              </li>
              <li>
                <a href="#">
                  <i className="icon fa fa-language"></i>
                </a>
                <p className="heading">
                  7.00 AM - 8.30 AM MUSIC CONCERT/KIRTAN
                </p>
              </li>
            </ul>
          </section>
        </div>

        <div className="clear"></div>
      </div> */}
      {/* footer section */}
      {/* <div className="wrapper row5">
        <div id="copyright" className="hoc clear">
          <p className="fl_left">
            Copyright &copy; 2016 - All Rights Reserved -
            <a href="#">Domain Name</a>
          </p>
          <p className="fl_right">
            Template by
            <a
              target="_blank"
              href="http://www.os-templates.com/"
              title="Free Website Templates"
            >
              OS Templates
            </a>
          </p>
        </div>
      </div> */}

      <a id="backtotop" href="#top">
        <i className="fa fa-chevron-up"></i>
      </a>

      <Footer />
    </>
  );
}

export default Home;
