import React from "react";

export default function Footer() {
  return (
    <div className="sub-footer">
      <div>
        Powered by Foxberry Technologies Pvt. Ltd © Goa Spiritual, All rights
        reserved.
      </div>
    </div>
  );
}
