import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import logo from "../assets/images/logo.png";
import { login } from "../redux/Action/authaction";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Action from "../redux/Action/authaction";

const Login = () => {
  const [first, setfirst] = useState({
    mobile: "",
    password: "",
    errors: [],
    isPasswordShown: false,
    redirect: false,
    noUser: "",
    loading: false,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleChange = (event) => {
    setfirst({ ...first, [event.target.name]: event.target.value });
  };

  const handleFormValidation = () => {
    const { mobile, password } = first;
    let errors = {};
    let formIsValid = true;

    if (!mobile) {
      formIsValid = false;
      errors["mobile"] = "*Please enter mobile.";
    } else if (!mobile.match(/^[a-zA-Z0-9@. ]*$/)) {
      formIsValid = false;
      errors["mobile"] = "*Please enter alphabet characters only.";
    }
    if (!password) {
      formIsValid = false;
      errors["password"] = "*Please enter Password.";
    }

    setfirst({ ...first, errors: errors });
    return formIsValid;
  };
  //Tostify
  const notify = () =>
    toast("Login Successfully", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  const notify1 = () =>
    toast("Something went wrong Unable to login ", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  //code to submit data
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (handleFormValidation()) {
      setfirst({ ...first, loading: true });

      const user = { mobile: first.mobile, password: first.password };
      axios
        .post("https://goapi.foxberry.link/v1/user/login", user)
        .then((response) => {
          console.log("res", response);
          if (response.data.user) {
            notify();
            dispatch(Action.loginSuccess(response.data.user.user));
            setTimeout(() => {
              navigate("/");
            }, 3100);
          } else {
            notify1();
            setfirst({ ...first, redirect: true, loading: false });
          }

          //   console.log("mobile", first.mobile);
          //   console.log("password", first.password);
          //   //alert("Logged in Successfully...!!");
          //   localStorage.setItem("mobile", first.mobile);
          //   localStorage.setItem("first_name", response.data.user.first_name);
          //   localStorage.setItem("last_name", response.data.user.last_name);
          //   localStorage.setItem("email", response.data.user.email);
          //   localStorage.setItem(
          //     "profile_image",
          //     response.data.user.profile_image
          //   );
          //   localStorage.setItem("city_name", response.data.user.city);
          //   if (response.data.user.sub_city) {
          //     localStorage.setItem("sub_city_name", response.data.user.sub_city);
          //   }
          //   localStorage.setItem("college_name", response.data.user.college_name);
          //   localStorage.setItem("college_code", response.data.user.college_code);
          //   localStorage.setItem("role", response.data.user.role);
          //   // console.log(res.data.user.districts);
          //   localStorage.setItem(
          //     "districts",
          //     JSON.stringify(response.data.user.districts)
          //   );
          //   localStorage.setItem("isLoggedIn", true);
          // setfirst({ ...first, redirect: true, loading: false });
        })
        .catch((error) => {
          // alert(error);
          setfirst({
            ...first,
            noUser: "Enter correct credentials!",
            loading: false,
          });
          console.log("url response in error-----", error);
        });
    }
  };
  //   render = () => {
  //     const { isPasswordShown } = this.state;
  if (first.loading) {
    return (
      <div
        className="item-center"
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    );
  }
  return (
    <>
      <div className="full-page">
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <div className="d-flex align-items-center w-100 header">
          <button
            className="btn btn-link text-white pl-0"
            onClick={() => {
              navigate(-1);
            }}
          >
            <i className="las la-arrow-left"></i>
          </button>
          <h2> Login</h2>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="login-container">
                <div className="logo text-center mt-5 mb-5">
                  <img src={logo} alt="logo" className="img-fluid" />
                </div>

                <h2 className="page-title text-center mb-4">Login </h2>

                <div className="row">
                  <div className="col-md-6 offset-md-3 col-sm-8 offset-sm-2">
                    <form
                      className="form"
                      onSubmit={(e) => {
                        handleSubmit(e);
                      }}
                    >
                      <div className="input-group common mb-4">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="las la-mobile"></i>
                          </span>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          maxLength="10"
                          name="mobile"
                          value={first.mobile}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          //   onKeyPress={() => {
                          //     checkMobileNumber();
                          //   }}
                          placeholder="Mobile Number"
                        />
                      </div>
                      <div className="errorMsg mb-2" style={{ color: "red" }}>
                        {first.errors.mobile}
                      </div>
                      <div className="input-group common mb-4">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="las la-comment-dots"></i>
                          </span>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter your password"
                          name="password"
                          value={first.password}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          // type={(isPasswordShown) ? "text" : "password"}
                        />
                      </div>
                      <div className="errorMsg mb-2" style={{ color: "red" }}>
                        {first.errors.password}
                      </div>
                      {/* <div className="input-group common resend-otp">
                                                <Link to="#" className="btn btn-link ml-auto">Resend OTP</Link>
                                            </div> */}
                      <div className="input-group common">
                        <button
                          className="btn btn-secondary d-flex align-items-center w-100"
                          to="/type"
                        >
                          Lets Go
                          <i
                            className="fa fa-angle-right ml-auto"
                            aria-hidden="true"
                          ></i>
                        </button>

                        <div className="or-block">
                          <span className="text">Or</span>
                        </div>

                        <Link
                          to="/signup/mobile"
                          className="btn btn-secondary new-member d-flex align-items-center w-100 mb-5"
                        >
                          New Member ?{" "}
                          <i
                            className="fa fa-angle-right ml-auto"
                            aria-hidden="true"
                          ></i>
                        </Link>
                        <div className="text-center w-100 mb-4">
                          <p className="text-muted font-weight-bold d-block">
                            By continuing you accept our{" "}
                            <Link to="#" className="text-blue">
                              <u>Terms & Conditions</u>
                            </Link>
                            .
                          </p>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
// };
export default Login;
