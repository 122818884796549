// import React, { useState, useEffect, useRef } from "react";
// import { Link } from "react-router-dom";
// import axios from "axios";
// import Scrollbar from "react-scrollbars-custom";
// import { Modal } from "react-bootstrap";
// import { Typeahead } from "react-bootstrap-typeahead";
// import dateFormat from "dateformat";
// import FloatingFooter from "../components/FloatingFooter";
// import EventImage1 from "../assets/images/event-image-1.png";
// import EventImage2 from "../assets/images/event-image-2.png";
// import { useNavigate } from "react-router-dom";
// function useWindowSize() {
//   const [size, setSize] = useState([window.innerHeight, window.innerWidth]);
//   useEffect(() => {
//     const handleResize = () => {
//       setSize([window.innerHeight, window.innerWidth]);
//     };
//     window.addEventListener("resize", handleResize);
//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);
//   return size;
// }

// function Events() {
//   const ref = useRef();
//   const [headerHeight, setHeaderHeight] = useState(null);
//   const [eventList, setAllEventData] = useState([]);
//   const [isEventList, setIsEventData] = useState(false);
//   const [title, setTitle] = useState();
//   const [startDate, setStartDate] = useState();
//   const [endDate, setEndDate] = useState();
//   const [startTime, setStartTime] = useState();
//   const [endTime, setEndTime] = useState();
//   const [entityId, setEntityId] = useState();
//   const [entityLink, setEntityLink] = useState();
//   const [entityStatus, setEntityStatus] = useState("PENDING");
//   const [eventDescription, setEventDescription] = useState();
//   const [eventImage, setEventImage] = useState();
//   const [height, width] = useWindowSize();
//   const [show, setShow] = useState(false);
//   const [loading, setloading] = useState(false);
//   const handleClose = () => setShow(false);
//   const navigate = useNavigate();
//   const handleShow = (eventDetails) => {
//     setShow(true);
//     setTitle(eventDetails.event_title);
//     setStartDate(eventDetails.event_date);
//     setEndDate(eventDetails.event_date);
//     setStartTime(eventDetails.event_start_time);
//     setEndTime(eventDetails.event_end_time);
//     setEntityId(eventDetails.event_id);
//     setEntityLink(eventDetails.event_link);
//     setEventDescription(eventDetails.event_description);
//     setEventImage(eventDetails.event_images);
//     setEntityStatus("PENDING");
//   };

//   let scrollHeight = height - (120 + 75);
//   console.log(
//     "🚀 ~ file: Issues.js ~ line 31 ~ Issues ~ scrollHeight",
//     scrollHeight
//   );
//   const [sidebar, setSidbar] = useState(false);

//   useEffect(() => {
//     console.warn("yin api", process.env);
//     setloading(true);
//     axios
//       .get(process.env.REACT_APP_API + "/event/get/event/with/event/head")
//       .then((response) => {
//         setloading(false);
//         console.log("-------------------EVENT DATA ---- : ", response.data);
//         if (response.data) {
//           setAllEventData(response.data);
//           setIsEventData(true);
//         }
//       })
//       .catch((error) => {
//         console.log("url response in error-----", error);
//       });
//   }, []);

//   useEffect(() => {
//     let headerHeight = ref.current.clientHeight;
//     setHeaderHeight(headerHeight);
//   }, []);

//   //Sidbar Function
//   const toggleSidebar = () => {
//     setSidbar(!sidebar);
//     if (sidebar) {
//       document.body.classNameName = "";
//     } else {
//       document.body.classNameName = "sb-sidenav-toggled";
//     }
//   };
//   let options = [
//     "Forum 1",
//     "Forum 2",
//     "Forum 3",
//     "Forum 4",
//     "Forum 5",
//     "Forum 6",
//   ];
//   const [singleSelections, setSingleSelections] = useState([]);

//   const addedToCalender = () => {
//     let data = {
//       yin_id: localStorage.getItem("yin_id"),
//       title: title,
//       start_date: startDate,
//       end_date: endDate,
//       start_time: startTime,
//       end_time: endTime,
//       entity_id: entityId,
//       entity_link: entityLink,
//       entity_status: entityStatus,
//     };
//     axios
//       .post(process.env.REACT_APP_TALK_API + "/calender/create", data)
//       .then((response) => {
//         console.log(
//           "-------------------DATA ADDED IN CALENDER ---- : ",
//           response
//         );
//         handleClose();
//       })
//       .catch((error) => {
//         console.log("url response in error-----", error);
//       });
//   };

//   return (
//     <>
//       <div
//         classNameName="header position-absolute clearfix issues-header events"
//         ref={ref}
//       >
//         <div classNameName="mb-3 d-flex align-items-center w-100">
//           <button
//             classNameName="btn btn-link text-white pl-0"
//             id="sidebarToggle"
//             onClick={toggleSidebar}
//           >
//             <i classNameName="fa fa-bars" aria-hidden="true"></i>
//           </button>
//           <h2>Main Events</h2>
//           {/* <Link to="/add-new-event" classNameName="btn btn-outline-light ml-auto">Add New Event <i classNameName="fa fa-plus" aria-hidden="true"></i></Link> */}
//         </div>
//         {/* <div classNameName="input-group mb-2 search">
//                     <input type="text" classNameName="form-control" placeholder="Search by categories of Issues" />
//                     <div classNameName="input-group-append">
//                         <span classNameName="input-group-text"><i classNameName="fa fa-search" aria-hidden="true"></i></span>
//                     </div>
//                 </div> */}
//         {/* <button
//           classNameName="btn btn-link text-white pl-0"
//           id="sidebarToggle"
//           onClick={toggleSidebar}
//         >
//           <i classNameName="fa fa-bars" aria-hidden="true"></i>
//         </button> */}
//       </div>

//       <div classNameName="issue-card-holder live-issues events">
//         <Scrollbar style={{ height: scrollHeight }}>
//           <div classNameName="container">
//             <div classNameName="row mb-3">
//               <div classNameName="col-sm-12">
//                 <div classNameName="form-group mb-2 search mx-2">
//                   <Typeahead
//                     id="basic-typeahead-single"
//                     labelKey="name"
//                     onChange={setSingleSelections}
//                     options={options}
//                     placeholder="Find the Forums which you really like to participate"
//                     selected={singleSelections}
//                   />
//                 </div>
//               </div>
//             </div>

//             <div classNameName="row events-container">
//               {isEventList ? (
//                 eventList.map((event, index) => {
//                   return (
//                     <div classNameName="col-sm-6" key={index}>
//                       <div classNameName="event-block clearfix">
//                         <div classNameName="p-2 text-left">
//                           <h2 classNameName="mb-1">
//                             {event.event_title.length > 30
//                               ? `${event.event_title.slice(0, 30)}...`
//                               : event.event_title}
//                           </h2>
//                           <p classNameName="time mb-2">
//                             {dateFormat(event.event_date, "dS mmm yyyy")} |{" "}
//                             {event.event_start_time} to {event.event_end_time}
//                           </p>
//                           {/* <button
//                           type="button"
//                           classNameName="btn btn-link btn-calender text-blue"
//                           onClick={() => handleShow(event)}
//                         >
//                           <i classNameName="las la-calendar"></i>
//                         </button> */}
//                         </div>
//                         <figure>
//                           <img
//                             src={
//                               event.event_images
//                                 ? event.event_images[0]?.url
//                                 : EventImage1
//                             }
//                             alt="EventImage1"
//                           />
//                         </figure>
//                         {/* <div classNameName="p-2">
//                         <p classNameName="event-desc truncate truncate-2 mb-1">
//                           {event.event_description}
//                         </p>
//                       </div> */}
//                         <div classNameName="text-center mt-2">
//                           <button
//                             onClick={() => {
//                               navigate("/user/subevents/" + event.event_id);
//                             }}
//                             classNameName="btn btn-primary px-5"
//                           >
//                             View Details
//                           </button>
//                         </div>
//                       </div>
//                     </div>
//                   );
//                 })
//               ) : (
//                 <div
//                   style={{
//                     height: "100%",
//                     width: "100%",
//                     display: "flex",
//                     alignItems: "center",
//                     justifyContent: "center",
//                   }}
//                 >
//                   <div className="spinner-border text-primary mt-5" role="status">
//                     <span className="sr-only">Loading...</span>
//                   </div>
//                 </div>
//               )}
//             </div>
//           </div>
//         </Scrollbar>
//       </div>

//       {/* <FloatingFooter /> */}

//       <Modal
//         show={show}
//         onHide={handleClose}
//         animation={true}
//         backdrop="static"
//         centered
//       >
//         <Modal.Header classNameName="text-center">
//           <Modal.Title>Add Event to Calendar ?</Modal.Title>
//           <button
//             type="button"
//             classNameName="close"
//             aria-hidden="true"
//             onClick={handleClose}
//           >
//             <i classNameName="las la-times"></i>
//           </button>
//         </Modal.Header>
//         <Modal.Body>
//           <div classNameName="col-sm-8 offset-sm-2 events-container">
//             <div classNameName="event-block clearfix mb-0">
//               <div classNameName="p-2 text-left">
//                 <h2 classNameName="mb-1">{title}</h2>
//                 <p classNameName="time mb-2">
//                   {dateFormat(startDate, "dS mmm yyyy")} | {startTime} to{" "}
//                   {endTime}
//                 </p>
//               </div>
//               <figure>
//                 <img
//                   src={eventImage ? eventImage : EventImage2}
//                   alt="EventImage2"
//                 />
//               </figure>
//               <div classNameName="p-2">
//                 <p classNameName="event-desc mb-1">{eventDescription}</p>
//               </div>
//             </div>
//           </div>
//         </Modal.Body>
//         <Modal.Footer>
//           <ul classNameName="list-inline text-center w-100">
//             <li classNameName="list-inline-item">
//               <button classNameName="btn btn-secondary" onClick={addedToCalender}>
//                 Yes
//               </button>
//             </li>
//             <li classNameName="list-inline-item">
//               <button classNameName="btn btn-secondary" onClick={handleClose}>
//                 No
//               </button>
//             </li>
//           </ul>
//         </Modal.Footer>
//       </Modal>
//     </>
//   );
// }

// export default Events;

import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Scrollbar from "react-scrollbars-custom";
import { Modal } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import dateFormat from "dateformat";
import FloatingFooter from "../components/FloatingFooter";
import EventImage1 from "../assets/images/event-image-1.png";
import EventImage2 from "../assets/images/event-image-2.png";
import Logoimage from "../assets/images/Picture1.jpg";
import { useNavigate } from "react-router-dom";
import img from "../assets/images/Picture1.jpg";
import "../Layout/styles/fontawesome-4.6.3.min.css";
import "../Layout/styles/framework.css";
import "../Layout/styles/layout.css";
import Yadnyalogo from "../assets/images/Yadnyalogo.webp";
import { logout } from "../redux/Action/authaction";
import { useDispatch } from "react-redux";
function Events() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <>
      {/* <!-- Top Background Image Wrapper --> */}
      {/* <div className="fixed-btn">
        <button className="btn p-3">Register Event</button>
      </div> */}
      <div className="topbgded">
        <div className="">
          <div className="wrapper row1">
            <header id="header" className="hoc clear">
              <div id="logo" className="fl_left">
                {/* <img src={Yadnyalogo} /> */}
              </div>
              <nav id="mainav" className="fl_right">
                <ul className="clear">
                  <li className="active">
                    <button
                      onClick={() => {
                        dispatch(logout());
                      }}
                      className="btn mt-3"
                      style={{ backgroundColor: "#6d0c27" }}
                    >
                      Logout
                    </button>
                  </li>
                </ul>
              </nav>
              <nav
                id="mainav"
                className="fl_right"
                style={{ visibility: "hidden" }}
              >
                <ul className="clear">
                  <li className="active">
                    <a href="index.html">Home</a>
                  </li>
                  <li>
                    <a className="drop" href="#">
                      Pages
                    </a>
                    <ul>
                      <li>
                        <a href="pages/gallery.html">Gallery</a>
                      </li>
                      <li>
                        <a href="pages/full-width.html">Full Width</a>
                      </li>
                      <li>
                        <a href="pages/sidebar-left.html">Sidebar Left</a>
                      </li>
                      <li>
                        <a href="pages/sidebar-right.html">Sidebar Right</a>
                      </li>
                      <li>
                        <a href="pages/basic-grid.html">Basic Grid</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="drop" href="#">
                      Dropdown
                    </a>
                    <ul>
                      <li>
                        <a href="#">Level 2</a>
                      </li>
                      <li>
                        <a className="drop" href="#">
                          Level 2 + Drop
                        </a>
                        <ul>
                          <li>
                            <a href="#">Level 3</a>
                          </li>
                          <li>
                            <a href="#">Level 3</a>
                          </li>
                          <li>
                            <a href="#">Level 3</a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <a href="#">Level 2</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="#">Link Text</a>
                  </li>
                  <li>
                    <a href="#">Link Text</a>
                  </li>
                </ul>
              </nav>
            </header>
          </div>

          <article id="pageintro" className="hoc clear">
            <h2 className="heading">
              What and Why we Celebrating the Som Yag Yadyna Festival 2023
            </h2>
            <div className="txtwrap">
              <p>
                With the 6-day Som Yag Yadnya Festival, we hope to achieve two
                goals, propitiate peace, energise and protect the Goan
                environment by destroying undesirable elements. It involves the
                chanting of selected mantras from three Vedas, the ancient
                sacred texts of Hinduism, Rig, Yajur and Sama- creating a sacred
                fire, believed to cleanse and transform the current energy into
                higher, positive vibrations. Additionally to the Yadnya, we will
                start each day with a walk, guided yoga, and meditation classes
                on side, preparing you for a mindful and blissful experience in
                Goa, followed by live music and meditation in the evening.
              </p>
              {/* <footer>
                <ul className="nospace inline pushright">
                  <li>
                    <a className="btn" href="#">
                      Posuere
                    </a>
                  </li>
                  <li>
                    <a className="btn inverse" href="#">
                      Porttitor
                    </a>
                  </li>
                </ul>
              </footer> */}
            </div>
          </article>
        </div>
      </div>
      {/* <!-- End Top Background Image Wrapper --> */}

      <div className="wrapper row3">
        <main className="eventcard container clear">
          {/* <!-- main body --> */}

          <div className="sectiontitle center">
            <h6 className="heading">Yadnya</h6>
            <p>
              This special, ancient old Yadnya (Ritual) provides a powerful
              boost of healing energy for those who participate and the
              surrounding environment. The atmosphere becomes medicinal,
              nutritious, and disease-free.
            </p>
          </div>
          <ul className="nospace group services ">
            <div className="eventcard">
              <li className="one_third first ">
                <article>
                  <i className="fa fa-clock-o"></i>
                  <h6 className="heading">Yoga & Meditation</h6>
                  <p>
                    Start your day with a walk, followed by a guided yoga
                    session and a blissful meditation session. All before we
                    begin with the Som Yag Yadnya, elevating you into the ideal
                    state of consciousness.
                  </p>
                  <button
                    className="btn mt-3"
                    onClick={() => {
                      navigate(
                        "/user/eventdetails/" +
                          "EVENT_SOMYAG_1674754779806"
                      );
                    }}
                  >
                    Register Event
                  </button>
                </article>
              </li>
            </div>
            <div className="eventcard">
              <li className="one_third ">
                <article>
                  <i className="fa fa-clock-o"></i>
                  <h6 className="heading">Satvic Market & Concert</h6>
                  <p>
                    Start your day with a walk, followed by a guided yoga
                    session and a blissful meditation session. All before we
                    begin with the Som Yag Yadnya, elevating you into the ideal
                    state of consciousness.
                  </p>
                  <button
                    className="btn mt-3"
                    onClick={() => {
                      navigate(
                        "/user/eventdetails/" + "EVENT_SOMYAG_1674754779806"
                      );
                    }}
                  >
                    {" "}
                    Register Event
                  </button>
                </article>
              </li>
            </div>
            {/* <div className="eventcard">
              <li className="one_third ">
                <article>
                  <i className="fa fa-clock-o"></i>
                  <h6 className="heading">Praesent vel urna</h6>
                  <p>
                    Enjoy beautifully crafted products from local vendors, while
                    listening to divine live music every evening.
                  </p>
                  <button className="btn mt-3"
                  onClick={() => {
                    navigate(
                      "/user/eventdetails/" + "EVENT_SOMYAG_1674754779806"
                    );
                  }}
                  
                  
                  > Register Event</button>
                </article>
              </li>
            </div> */}
          </ul>

          {/* <!-- / main body --> */}
          <div className="clear"></div>
        </main>
      </div>

      {/* <div
        className="wrapper bgded overlay light"
        style={{ backgroundImage: `${img}` }}
      >
        <div className="hoc container clear">
          <div className="group element">
            <article className="one_third first">
              <img src={img} alt="" />
              <div className="txtwrap">
                <h6 className="heading">Faucibus vestibulum</h6>
                <p>
                  Amet ultricies curabitur eget tempus urna ut porta leo ipsum
                  aliquam maximus sagittis erat malesuada [&hellip;]
                </p>
                <footer>
                  <a href="#">Read More</a>
                </footer>
              </div>
            </article>
            <article className="one_third">
              <img src={img} alt="" />
              <div className="txtwrap">
                <h6 className="heading">Metus blandit fringilla</h6>
                <p>
                  Lorem lacinia nec suspendisse lacinia sapien ac eros ornare
                  consequat duis sit amet tortor sapien nam [&hellip;]
                </p>
                <footer>
                  <a href="#">Read More</a>
                </footer>
              </div>
            </article>
            <article className="one_third">
              <img src={img} alt="" />
              <div className="txtwrap">
                <h6 className="heading">Sodales molestie sit</h6>
                <p>
                  Magna quis tempus tortor mattis ut vestibulum justo lorem
                  viverra eget ipsum volutpat dapibus velit [&hellip;]
                </p>
                <footer>
                  <a href="#">Read More</a>
                </footer>
              </div>
            </article>
          </div>

          <div className="clear"></div>
        </div>
      </div> */}

      <div className="wrapper bgded overlay " style={{ background: "#fa6900" }}>
        <div className="hoc container clear eventlist">
          <section className="one_half first">
            <ul className="nospace iconlist">
              <li>
                <a href="#">
                  <i className="icon fa fa-language"></i>
                </a>
                <p className="heading">
                  6.30 AM - 7.00 AM CLICK IN ,DROP YOUR BAG & GRAB TEA
                </p>
              </li>
              <li>
                <a href="#">
                  <i className="icon fa fa-language"></i>
                </a>
                <p className="heading">7.00 AM - 7.45 PM WALK</p>
              </li>
              <li>
                <a href="#">
                  <i className="icon fa fa-language"></i>
                </a>
                <p className="heading">8.00 AM - 9.00 AM YOGA</p>
              </li>
              <li>
                <a href="#">
                  <i className="icon fa fa-language"></i>
                </a>
                <p className="heading">9.15 AM - 9.30 AM MEDITATION</p>
              </li>
              <li>
                <a href="#">
                  <i className="icon fa fa-language"></i>
                </a>
                <p className="heading">9.15 AM - 9.30 AM MEDITATION</p>
              </li>
            </ul>
          </section>
          <section className="one_half first">
            <ul className="nospace iconlist">
              <li>
                <a href="#">
                  <i className="icon fa fa-language"></i>
                </a>
                <p className="heading">9.30 AM - 10.00 AM PRASADAM</p>
              </li>
              <li>
                <a href="#">
                  <i className="icon fa fa-language"></i>
                </a>
                <p className="heading">10.00 AM - 6.00 AM YADNYA</p>
              </li>
              <li>
                <a href="#">
                  <i className="icon fa fa-language"></i>
                </a>
                <p className="heading">12.30 AM - 1.00 AM LUNCH</p>
              </li>
              <li>
                <a href="#">
                  <i className="icon fa fa-language"></i>
                </a>
                <p className="heading">
                  6.15 AM - 7.00 AM EVENING MEDITATION (SUNSET AROUND 6.20 PM)
                </p>
              </li>
              <li>
                <a href="#">
                  <i className="icon fa fa-language"></i>
                </a>
                <p className="heading">
                  7.00 AM - 8.30 AM MUSIC CONCERT/KIRTAN
                </p>
              </li>
            </ul>
          </section>
        </div>

        <div className="clear"></div>
      </div>
      {/* footer section */}
      {/* <div className="wrapper row5">
        <div id="copyright" className="hoc clear">
          <p className="fl_left">
            Copyright &copy; 2016 - All Rights Reserved -
            <a href="#">Domain Name</a>
          </p>
          <p className="fl_right">
            Template by
            <a
              target="_blank"
              href="http://www.os-templates.com/"
              title="Free Website Templates"
            >
              OS Templates
            </a>
          </p>
        </div>
      </div> */}

      <a id="backtotop" href="#top">
        <i className="fa fa-chevron-up"></i>
      </a>
    </>
  );
}

export default Events;
