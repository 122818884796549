import React, { Component } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import logo from "../assets/images/logo.png";
import { login } from "../redux/Action/authaction";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Action from "../redux/Action/authaction";
import Yadnyalogo from "../assets/images/Yadnyalogo.webp";

import { matchotp } from "../redux/Action/authaction";

const VerifySignupotp = () => {
  const [first, setfirst] = useState({
    otp: "",
    errors: [],
    isPasswordShown: false,
    redirect: false,
    noUser: "",
    loading: false,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const navigation = useNavigate();

  const mobiless = useSelector((state) => state);
  // console.log("dsfghjkjgcxcvhjk 777777", mobiless);
  const handleChange = (event) => {
    setfirst({ ...first, [event.target.name]: event.target.value });
  };

  const handleFormValidation = () => {
    const { otp } = first;
    let errors = {};
    let formIsValid = true;
    if (!otp) {
      formIsValid = false;
      errors["otp"] = "*Please enter otp.";
    }
    setfirst({ ...first, errors: errors });
    return formIsValid;
  };

  //code to submit data
  const notify = () =>
    toast.error("OTP does not match", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  const notify1 = () =>
    toast("OTP Match", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  const handleClick = async (event) => {
    // console.log("fghjcjhdvfdgyf");
    event.preventDefault();
    const user = { mobile: mobiless.auth.mobile, otp: first.otp };

    if (handleFormValidation()) {
      // setfirst({ ...first});
      setfirst({ ...first, loading: true });
      await matchotp(user, dispatch)
        .then((res) => {
          setfirst({ ...first, loading: false });
          dispatch(Action.loginSuccess(res.data.data));

          if (res?.data?.message === "OTP Match") {
            notify1();
            //  console.log('res?.data?.data ',res?.data?.data );
            if (res?.data?.data === null) {
              setTimeout(() => {
                navigate("/signup");
              }, "3100");
            } else {
              setTimeout(() => {
                navigate(-2);
              }, "3000");
            }
          } else {
            notify();
          }
        })
        .catch((err) => {
          setfirst({ ...first, loading: false });
        });
      // console.log("sdfghjnnjn response otp", res.data);
    }
  };

  //   render = () => {
  //     const { isPasswordShown } = this.state;
  if (first.redirect) {
    return <Link to="/home" />;
  }
  if (first.loading) {
    return (
      <>
      <div
        role="status"
        style={{
          width: "100wv",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div class="spinner-border"></div>
      </div>
    </>
    );
  }
  return (
    <>
      <div className="container">
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <div>
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex align-items-center  w-100 header">
                <button
                  className="btn btn-link text-white pl-0"
                  onClick={() => {
                    navigation(-1);
                  }}
                >
                  <i className="las la-arrow-left"></i>
                </button>
                <h2>Verify Signup OTP </h2>
              </div>
              <div className="login-container">
                <div className="logo text-center ">
                  <img src={Yadnyalogo} alt="logo" className="img-fluid" />
                </div>

                <h2 className="page-title text-center mb-4">
                  Verify Signup OTP{" "}
                </h2>

                <div className="row">
                  <div className="col-md-6 offset-md-3 col-sm-8 offset-sm-2">
                    <form
                      className="form"
                      onSubmit={(e) => {
                        handleClick(e);
                      }}
                    >
                      <div className="input-group common mb-4">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="las la-mobile"></i>
                          </span>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          maxLength="10"
                          name="otp"
                          value={first.otp}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          //   onKeyPress={() => {
                          //     checkMobileNumber();
                          //   }}
                          placeholder="Enter OTP"
                        />
                      </div>
                      <div className="errorMsg mb-2" style={{ color: "red" }}>
                        {first.errors.otp}
                      </div>
                      {/* <div className="input-group common resend-otp">
                                                <Link to="#" className="btn btn-link ml-auto">Resend OTP</Link>
                                            </div> */}
                      <div className="input-group common">
                        <button
                          className="btn btn-secondary  w-100"
                          style={{ display: "flex", justifyContent: "center" }}
                          to="/type"
                        >
                          Verify
                          {/* <i
                            className="fa fa-angle-right ml-auto"
                            aria-hidden="true"
                          ></i> */}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
// };
export default VerifySignupotp;
