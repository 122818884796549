import axios from "axios";
import { BASE_URL } from "../../utils/urls";

import {
  LOGIN_SUCCESS,
  LOGOUT,
  SEND_OTP_SUCCESS,
  MOBILE,
  EVENTDETAILS,
} from "../constant/authconstant";

export const loginRequest = () => {
  return {
    type: "LOGIN_REQUEST",
  };
};

export const loginSuccess = (data) => {
  console.warn("action", data);
  return {
    type: LOGIN_SUCCESS,
    payload: data,
  };
};

export const loginFailure = (error) => {
  return {
    type: "LOGIN_FAILURE",
    payload: error,
  };
};

export const sendOtpSuccess = (data) => {
  console.log("xvchvc", data);
  return {
    type: SEND_OTP_SUCCESS,
    payload: data,
  };
};

export const sendOtpFailure = (error) => {
  return {
    type: "SEND_OTP_FAILURE",
    payload: error,
  };
};
export const verifySuccess = (data) => {
  return {
    type: "VERYFY_OTP_SUCCESS",
    payload: data,
  };
};
export const eventDetails = (data) => {
  return {
    type: EVENTDETAILS,
    payload: data,
  };
};

export const verifyFailure = (error) => {
  return {
    type: "VERIFY_OTP_FAILURE",
    payload: error,
  };
};
export const logout = () => {
  return {
    type: LOGOUT,
  };
};

// export function login(data, dispatch) {
//   console.log("data", data);
//   // let datas = {
//   //   mobile: data.mobile,
//   //   password: data.password,
//   // };
//   return axios
//     .post("https://goapi.foxberry.link/v1/user/login", data)
//     .then((response) => {
//       console.log("response", response);
//       if (response.user) {
//         dispatch(loginSuccess(response));
//       }
//       return response;
//     })
//     .catch((error) => {
//       console.log("error", error);
//       const errorMessage = error.response;
//       dispatch(loginFailure(errorMessage));
//     });
// }
export function signupmobile(data, dispatch) {
  let datas = {
    mobile: data.mobile,
  };
  return axios
    .post(BASE_URL + "/user/sendsmstomobile", datas)
    .then((response) => {
      console.log(response.data.mobile);
      if (response.data.mobile) {
        dispatch(sendOtpSuccess(response.data.mobile));
      }
      return response;
    })
    .catch((error) => {
      const errorMessage = error.response;
      dispatch(loginFailure(errorMessage));
    });
}
export function matchotp(data, dispatch) {
  let datas = {
    mobile: data.mobile,
    otp: data.otp,
  };
  return axios
    .post(BASE_URL + "/user/verifylogin", datas)
    .then((response) => {
      if (response.user) {
        dispatch(verifySuccess(response.user));
      }
      console.log("response", response);
      return response;
    })
    .catch((error) => {
      const errorMessage = error.response;
      dispatch(loginFailure(errorMessage));
    });
}
