import React, { Component, useEffect } from "react";

// import logo1 from "../Assets/images/transparent-logo.png";
// import 'font-awesome/css/font-awesome.min.css';
import Yadnyalogo from "../assets/images/Yadnyalogo.webp";

import { Redirect, Link } from "react-router-dom";
import logo from "../assets/images/logo.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
// import Box from '@mui/material/Box';
// import TextField from '@mui/material/TextField';
import * as Action from "../redux/Action/authaction";

const Signupp = () => {
  const navigation = useNavigate();
  const mobiless = useSelector((state) => state);
  const dispatch = useDispatch();

  const [state, setState] = React.useState({
    first_name: "",
    last_name: "",
    dob: "",
    mobile: mobiless.auth.mobile,
    gender: "",
    pincode: "",
    errors: [],
    password: "",
    adharcardNo: "",
    adharcardURL: "",
    EventId: "",
  });
  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };
  const handleFormValidation = () => {
    const { first_name, last_name, dob } = state;
    // console.log(state);
    let errors = {};
    let formIsValid = true;

    // console.log("candidate data", state);
    if (!first_name) {
      formIsValid = false;
      errors["first_name"] = "*Please enter First name.";
    } else if (!/^[a-zA-Z]+$/.test(first_name.trim())) {
      formIsValid = false;
      errors["first_name"] = "*Please enter alphabet characters only.";
    }

    if (!last_name) {
      formIsValid = false;
      errors["last_name"] = "*Please enter Last name.";
    } else if (!/^[a-zA-Z]+$/.test(last_name.trim())) {
      formIsValid = false;
      errors["last_name"] = "*Please enter alphabet characters only.";
    }

    // if (!dob) {
    //   formIsValid = false;
    //   errors["dob"] = "*Please enter date of birth.";
    // }

    // if (!mobile) {
    //     formIsValid = false;
    //     errors["mobile"] = "*Please enter mobile no.";
    // }
    // else if (!mobile.match(/^[0-9]{10}$/)) {
    //     formIsValid = false;
    //     errors["mobile"] = "*Please enter valid mobile no.";
    // }
    // if (!password) {
    //     formIsValid = false;
    //     errors["password"] = "*Please enter password";
    // }
    // if(!pincode){
    //     formIsValid = false;
    //     errors["pincode"] = "*Please enter pin code";

    // }
    // if(!adharcardNo){
    //     formIsValid = false;
    //     errors["adharcardNo"] = "*Please enter pin code";

    // }

    setState({
      ...state,
      errors: errors,
    });
    return formIsValid;
  };
  const handleSubmit = (event) => {
    event.preventDefault();
  
    // console.log("inside form validation1...");
    if (handleFormValidation()) {
      // console.log("inside form validation");
      setState({
        loading: true,
      });

      let formData = {
        first_name: state.first_name,
        last_name: state.last_name,
        year_of_birth: state.dob,
        mobile: state.mobile,
        gender: state.gender,

        password: state.password,
      };
      // console.log("data", formData);

      axios
        .post(process.env.REACT_APP_API + "/user/insertuser", formData)
        .then((res) => {
          // console.log("dvghvbjhvjh", res);
          // console.log("dvghvbjhvjh11112", formData);
          // {res.data.data ? notify():notify1()}
          if (res.data.data) {
            notify();
            let VAL = JSON.parse(localStorage.getItem("EventId:"));
            console.warn("res.data.data", res.data.data);
            dispatch(Action.loginSuccess(res.data.data));
            setTimeout(() => {
              // console.log("Navigating")
              navigation(`/user/eventdetails/${VAL}`);
            }, 3100);
          } else {
            notify1();
          }
        })
        .catch((err) => {
          console.log("Api Error", err);
        });
    }
  };

  const notify1 = () =>
    toast("User Already Exist  ", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  const notify = () =>
    toast("Register Successfully", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  const geteventid = () => {
    const eventid = localStorage.getItem("EventId:");

    setState({ ...state, eventid: eventid });
  };

  useEffect(() => {
    geteventid();
  }, []);

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="row m-2">
        <div className="col-md-12">
          {
            // console.log("state.EventId", state.EventId)
          }
          <div className="d-flex align-items-center  w-100 header">
            <button
              className="btn btn-link text-white pl-0"
              onClick={() => {
                navigation(-1);
              }}
            >
              <i className="las la-arrow-left"></i>
            </button>
            <h2> Signup</h2>
          </div>
          <div className="logo text-center mt-5 mb-5">
            <img src={Yadnyalogo} alt="logo" className="img-fluid" />
          </div>

          <form
            id="loginForm"
            className="selectBox p-4"
            onSubmit={(event) => {
              handleSubmit(event);
            }}
            method="post"
            encType="multipart/form-data"
          >
            <h2 className="page-title text-center mb-4">Signup</h2>
            <div className="input-group common mb-4">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="las la-user"></i>
                </span>
              </div>
              <input
                type="text"
                className="form-control"
                value={state?.first_name}
                onChange={handleChange}
                name="first_name"
                placeholder="First Name"
                // ref={textInputFirstName}
              />
            </div>
            <div className="errorMsg" style={{ color: "red" }}>
              {state?.errors?.first_name}
            </div>

            <div className="input-group common mb-4 mt-3">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="las la-user"></i>
                </span>
              </div>
              <input
                type="text"
                className="form-control"
                value={state?.last_name}
                onChange={handleChange}
                name="last_name"
                placeholder="Last Name"
                // ref={textInputFirstName}
              />
            </div>
            <div className="errorMsg" style={{ color: "red" }}>
              {state.errors?.last_name}
            </div>

            <div className="input-group common mb-4  mt-3">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="la la-calendar"></i>
                </span>
              </div>
              <input
                type="date"
                className="form-control"
                placeholder="Year Of Birth"
                value={state?.dob}
                onChange={handleChange}
                name="dob"
                // ref={textInputdob}
              />
            </div>
            <div className="errorMsg" style={{ color: "red" }}>
              {state?.errors?.dob}
            </div>

            <div className="input-group common mb-4 mt-3">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="las la-mobile"></i>
                </span>
              </div>
              <input
                className="form-control"
                placeholder="mobile"
                value={state?.mobile}
                onChange={handleChange}
                disabled
                type="text"
                name="mobile"
                maxLength="10"
              />
            </div>

            <div className="input-group common mb-4 mt-3">
              <ul className="list-inline mb-0">
                <li className="list-inline-item mr-3">
                  <label className="mb-0">Gender :</label>
                </li>
                <li className="list-inline-item mr-3">
                  <div className="radio">
                    <input
                      type="radio"
                      id="male"
                      name="gender"
                      onChange={() => setState({ ...state, gender: "male" })}
                      // defaultChecked="true"
                    />
                    <label htmlFor="male" className="mb-0">
                      Male
                    </label>
                  </div>
                </li>
                <li className="list-inline-item mr-3">
                  <div className="radio">
                    <input
                      type="radio"
                      id="female"
                      name="gender"
                      onChange={() =>
                        setState({
                          ...state,
                          gender: "female",
                        })
                      }
                    />
                    <label htmlFor="female" className="mb-0">
                      Female
                    </label>
                  </div>
                </li>
                <li className="list-inline-item mr-3">
                  <div className="radio">
                    <input
                      type="radio"
                      id="other"
                      name="gender"
                      onChange={() =>
                        setState({
                          ...state,
                          gender: "other",
                        })
                      }
                    />
                    <label htmlFor="other" className="mb-0">
                      Other
                    </label>
                  </div>
                </li>
              </ul>
            </div>
            <div className="errorMsg">{state?.errors?.gender}</div>

            <div className="text-center w-100 mb-2 mt-4">
              <p className="font-weight-bold d-block">
                By continuing you accept our{" "}
                <a
                  target="_blank"
                  href="https://gsda-images.s3.ap-south-1.amazonaws.com/gsad/Amravati/ChandurBazar/Visroli/1_IEC_&_Community_Mobilization_Program/Distribution%20of%20E-book/63a47c22aa5f45ec3427f753/1675250856604_.pdf"
                  className="text-blue"
                >
                  <u>Terms & Conditions</u>
                </a>
                .
              </p>
            </div>

            <div className="input-group common">
              <button
                type="submit"
                className="btn btn-secondary d-flex align-items-center w-100"
                style={{ justifyContent: "center" }}
              >
                Submit
              </button>

              <div
                style={{
                  height: "1px",
                  margin: "40px 0px",
                  width: "100%",
                  background: "grey",
                }}
              ></div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default Signupp;
