import React, { Component } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import logo from "../assets/images/logo.png";
import { sendOtpSuccess, signupmobile } from "../redux/Action/authaction";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Yadnyalogo from "../assets/images/Yadnyalogo.webp";

import * as Action from "../redux/Action/authaction";
import { async } from "q";

const SignupMobile = () => {
  var phonenoExp = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;

  const [first, setfirst] = useState({
    mobile: "",
    errors: "",
    loader: false,
  });

  const dispatch = useDispatch();
  const navigation = useNavigate();

  const handleChange = (event) => {
    setfirst({ ...first, [event.target.name]: event.target.value });
  };

  const handleFormValidation = () => {
    const { mobile } = first;
    let errors = {};
    let formIsValid = true;

    if (!mobile) {
      formIsValid = false;
      errors["mobile"] = "*Please enter mobile.";
    } else if (
      mobile.match(/[A-Za-z+@#$&%!~]/) ||
      (mobile.length !== 10 && mobile.length !== 0)
    ) {
      formIsValid = false;
      errors["mobile"] = "*Please enter valid mobile number.";
    }

    setfirst({ ...first, errors: errors });
    return formIsValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (handleFormValidation()) {
      setfirst({ ...first, loader: true });
      const user = { mobile: first.mobile, password: first.password };
      await signupmobile(user, dispatch)
        // .then((res)=>{
        //   console.log("response Api",res);
        // })

        .then((response) => {
          setfirst({ ...first, loader: false });
          console.log("Response form api:", response);

          setfirst({ ...first, redirect: true, loading: false });
          console.log("response.data.message", response.data.message);
          if (response.data.login_otp) {
            notify();
            setTimeout(() => {
              navigation("/signup/otp");
            }, 2100);
          } else {
            notify1();
          }
        })
        .catch((error) => {
          setfirst({ ...first, loader: false });
          notify1();
          setfirst({
            ...first,
            noUser: "Enter correct credentials!",
            loading: false,
          });
          console.log("url response in error-----", error);
        });
    }
  };

  const handleClick = async (event) => {
    event.preventDefault();

    if (handleFormValidation()) {
      setfirst({ ...first, loader: true });
      const user = { mobile: first.mobile, password: first.password };
      await signupmobile(user, dispatch)
        // .then((res)=>{
        //   console.log("response Api",res);
        // })

        .then((response) => {
          setfirst({ ...first, loader: false });
          console.log("Response form api:", response);

          setfirst({ ...first, redirect: true, loading: false });
          console.log("response.data.message", response.data.message);
          if (response.data.login_otp) {
            notify();
            setTimeout(() => {
              navigation("/signup/otp");
            }, 2100);
          } else {
            notify1();
          }
        })
        .catch((error) => {
          setfirst({ ...first, loader: false });
          notify1();
          setfirst({
            ...first,
            noUser: "Enter correct credentials!",
            loading: false,
          });
          console.log("url response in error-----", error);
        });
    }
  };
  const notify = () =>
    toast("OTP sent successfully", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  const notify1 = () =>
    toast("Something went wrong Unable to send Otp ", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  if (first.loader) {
    return (
      <>
        <div
          role="status"
          style={{
            width: "100wv",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div class="spinner-border"></div>
        </div>
      </>
    );
  }
  return (
    <>
      <div className="full-page">
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex align-items-center w-100 header">
                <button
                  onClick={() => {
                    navigation(-1);
                  }}
                  className="btn btn-link text-white pl-0"
                >
                  <i className="las la-arrow-left"></i>
                </button>
                <h2> Signup </h2>
              </div>
              <div className="login-container">
                <div className="logo text-center ">
                  <img src={Yadnyalogo} alt="logo" className="img-fluid" />
                </div>

                <h2 className="page-title text-center mb-4">Signup Mobile</h2>

                <div className="row">
                  <div className="col-md-6 offset-md-3 col-sm-8 offset-sm-2">
                    <form className="form">
                      <div className="input-group common mb-4">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="las la-mobile"></i>
                          </span>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          maxLength="10"
                          minLength="10"
                          name="mobile"
                          value={first.mobile}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          placeholder="Mobile Number"
                        />
                      </div>
                      <div className="errorMsg mb-2" style={{ color: "red" }}>
                        {first.errors.mobile}
                      </div>

                      <div className="input-group common">
                        <button
                          type="submit"
                          className="btn btn-secondary  w-100  "
                          style={{ display: "flex", justifyContent: "center" }}
                          // to="/type"
                          onClick={(event) => {
                            handleClick(event);
                          }}
                        >
                          Send OTP
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
// };
export default SignupMobile;
