import {
  LOGIN_SUCCESS,
  LOGOUT,
  SEND_OTP_SUCCESS,
  MOBILE,
  EVENTDETAILS,
} from "../constant/authconstant";

const initialState = {
  loading: false,
  userDetails: null,
  error: null,

  mobile: null,
  eventDetails:null,
};

const authReducer = (state = initialState, { type, payload }) => {
  console.log("Auth Reducer: ", { type, payload });
  switch (type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        userDetails: payload,
        error: null,
      };

    case LOGOUT:
      return {
        ...state,
        userDetails: null,
        error: null,
      };
    case SEND_OTP_SUCCESS:
      return {
        ...state,
        mobile: payload,
        error: null,
      };
    case EVENTDETAILS:
      return {
        ...state,
        eventDetails: payload,
        error: null,
      };
    default:
      return state;
  }
};

export default authReducer;
