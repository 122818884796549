import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.min.css";

import * as Icon from "react-bootstrap-icons";

import axios from "axios";
import { useSelector } from "react-redux";
import useRazorpay from "react-razorpay";

import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { AiOutlineFilePdf } from "react-icons/ai";

import moment from "moment/moment";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";

function Eventdetails(props, { embedId, navigation }) {
  const [show, setShow] = useState(false);
  const [registartion, setregistartion] = useState("");
  const [isEventDetails, setIsEventDetails] = useState(false);
  const handleClose = () => setShow(false);
  const params = useParams();
  const [imageSlider, setImageSlider] = useState();
  const [imageSlider2, setImageSlider2] = useState();
  const [imageSlider3, setImageSlider3] = useState();
  const [message, setMessage] = useState();
  const [allData, setAllData] = useState();
  const [Event, setEvent] = React.useState();
  const [loader, setloader] = useState(false);
  const [details, setdetails] = useState({
    amount: "",
    startpayment: false,
    eventvalue: "",
    sponcer: [],
    RegistrationCoutnt: 0,
  });
  const [pdf, setpdf] = useState("");

  //For geting state value

  const userdetails = useSelector((state) => state.auth?.userDetails);
  const userdetailss = useSelector((state) => state.auth?.userDetails?.mobile);
  const userid = useSelector((state) => state?.auth?.userDetails?.user_id);
  console.log("navigation", props);
  const getUserdetails = async () => {
    const eventDetails = await axios.get(
      process.env.REACT_APP_API +
        `/event/list/user-id/${userid}?type=OPEN&page=0`
    );
    console.warn("eventDetails", eventDetails);
    if (eventDetails?.data) {
      setEvent(eventDetails?.data[0][0]?.event_id);
    }
  };
  // console.log(" hjbvjbv Event Data",Event[0]);

  //rezor pay
  const Razorpay = useRazorpay();
  //assign variable to navigate
  const navigate = useNavigate();

  //Add member in evevnt

  const AddMemberinvent = async () => {
    const event_id = params.event_id;
    const member = userdetails;
    const data = {
      event_id,
      member,
    };
    const res = await axios.post(
      process.env.REACT_APP_API + "/registerevent/add/event/member",
      data
    );

    setMessage(res.data.data.message);

    return res;
  };

  useEffect(() => {
    getEvent();
    getCount();
    generateQr();

    getUserdetails();
  }, []);

  const profile11 = () => {};
  const generateQr = () => {
    const event_id = params.event_id;
    const mobile = userdetailss;
    const data = {
      event_id,
      mobile,
    };

    axios
      .post(process.env.REACT_APP_API + "/eventpdf/pdf/get", data)
      .then((res) => {
        // console.log("QR GENERATION DATA", res);
      });
  };

  const getEvent = async () => {
    setIsEventDetails(true);
    await axios
      .get(
        process.env.REACT_APP_API + "/event/list/event_id/" + params.event_id
      )
      .then((response) => {
        // console.log("-------------------EVENT DATA ---- : ", response.data[0]);

        setIsEventDetails(false);
        setImageSlider(response.data[0].event_images);
        setImageSlider2(response.data[0].sponsors);
        setImageSlider3(response.data[0].event_documents);

        setdetails({
          ...details,
          amount: response.data[0]?.event_amount,
          eventvalue: response.data[0],
        });
      })
      .catch((error) => {
        console.log("url response in error-----", error);
      });
  };

  const getCount = async () => {
    await axios
      .post(process.env.REACT_APP_API + "/event/get/event/member-count/", {
        event_id: params.event_id,
      })
      .then((response) => {
        // console.log(
        //   "-------------------Total count ---- : ",
        //   response?.data?.data
        // );
        setregistartion(response?.data?.data);
      })
      .catch((error) => {
        console.log("url response in error getcount-----", error);
      });
  };
  // console.log("Details 1222", details);
  //handle payment
  const notify = () =>
    toast("Please! Login First", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  const notify1 = () =>
    toast(`${message}`, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const handlePayment = async () => {
    // let navg=details?.eventvalue?.event_id;
    localStorage.setItem(
      "EventId:",
      JSON.stringify(details?.eventvalue?.event_id)
    );
    console.warn("----userdetails----", userdetails);
    if (!userdetails) {
      notify();
      setTimeout(() => {
        navigate("/signup/mobile");
      }, 2100);
    } else {
      await AddMemberinvent()
        .then((res) => {
          setloader(false);
          // console.log("setMaeesage ghudghgf", res.data.message);
          let data = {
            user: userdetails,
            event_id: params.event_id,
          };
          if (message === "Member already added") {
            setTimeout(() => {
              navigate(-2);
            }, 2100);
            notify1();

            // if(res.data.message === "Member already added"){

            //   setTimeout(() => {
            //     navigate(-1);
            //   }, 2100);

            // }
          } else {
            let obj = {
              data: {
                user: userdetails,
                event_id: params.event_id,
              },
              event: details,
            };
            navigate("/user/thankyou", { state: obj });
          }
        })
        .catch((err) => {
          setloader(false);
        });
    }
  };

  async function createOrder() {
    let orderDetails = {
      amount: Number(details?.amount) * 100,
      currency: "INR",
      receipt: `MOBILE_PAY_${Date.now().toString()}`,
    };
    try {
      const createOrderResponse = await axios.post(
        process.env.REACT_APP_RAZORPAYAPI + "/orders",
        orderDetails,
        {
          auth: process.env.REACT_APP_REZORPAY_TEST,
        }
      );

      return createOrderResponse;
    } catch (error) {
      return error;
    }
  }

  async function handlePaymentInformationSync(t, i) {
    try {
      const response = await axios.post(
        "https://yin-api.foxberry.link/v2/payments/transactions",
        t,
        i
      );

      // onRefresh();
    } catch (error) {
      console.warn(error);
    }
  }

  const text = details.eventvalue.event_youtube_video;

  const id = details.eventvalue
    ? text.slice(details.eventvalue.event_youtube_video.indexOf("=") + 1)
    : "";
  //function for open google map
  // console.log("first", details.eventvalue.event_longitude);
  const showInMapClicked = () => {
    window.open(
      "https://maps.google.com?q=" +
        details.eventvalue.event_latitude +
        "," +
        details.eventvalue.event_longitude
    );
  };
  // console.log("Event9696", Event);
  if (loader) {
    return (
      <>
        <div
          role="status"
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="spinner-border"></div>
        </div>
      </>
    );
  }
  return (
    <>
      {isEventDetails ? (
        <div
          role="status"
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="spinner-border"></div>
        </div>
      ) : (
        <div
          className="h-100 mb-5"
          style={{
            backgroundColor: "#F0F0F0",
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          <div>
            <div className="header">
              <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />
              <div className="d-flex align-items-center w-100">
                <button
                  onClick={() => {
                    navigate("/");
                  }}
                  className="btn btn-link text-white pl-0"
                >
                  <i className="las la-arrow-left"></i>
                </button>
                <h6 style={{ color: "white" }}> Details</h6>
              </div>
            </div>
            {/* heaser end */}

            <div className="box w-100">
              {/* <div
                className="slider-area"
                style={{
                  backgroundImage: ` url(${imageSlider})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "100% 100%",
                }}
              >
       
              </div> */}
              <Carousel className="mb-4" style={{ borderRadius: "12px" }}>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={imageSlider}
                    alt="First slide"
                    style={{ borderRadius: "12px" }}
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={imageSlider2}
                    alt="Second slide"
                    style={{ borderRadius: "12px" }}
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={imageSlider3}
                    alt="Third slide"
                    style={{ borderRadius: "12px" }}
                  />
                </Carousel.Item>
              </Carousel>
              <div className="d-flex justify-content-start align-items-center text-dark mb-3">
                <Icon.CardHeading
                  className="me-3"
                  size={25}
                  style={{ color: "#e5851f" }}
                />
                <h6>
                  <strong> Title :-</strong> {details?.eventvalue.event_title}
                </h6>
              </div>

              <div className="d-flex justify-content-start align-items-center text-dark mb-3">
                <Icon.CardList
                  className="me-3"
                  size={25}
                  style={{ color: "#e5851f" }}
                />
                <h6>
                  <strong> Registrations :-</strong> {registartion}
                </h6>
              </div>

              <div className="d-flex justify-content-start align-items-center text-dark mb-3">
                <Icon.CalendarCheck
                  className="me-3"
                  size={25}
                  style={{ color: "#e5851f" }}
                />
                <h6>
                  <strong> Date :- {""}</strong>
                  <span>
                    <span>
                      {moment(details?.eventvalue.event_date).format(
                        "DD-MMM-YYYY "
                      )}
                    </span>
                    {""}
                    <span>
                      &nbsp; to &nbsp;
                      {moment(details?.eventvalue.event_end_date).format(
                        "DD-MMM-YYYY"
                      )}
                    </span>
                    {"  "}
                  </span>
                </h6>
              </div>

              <div className="d-flex justify-content-start align-items-center text-dark mb-3">
                <Icon.Alarm
                  className="me-3"
                  size={25}
                  style={{ color: "#e5851f" }}
                />
                <h6>
                  {" "}
                  <strong> Time :- </strong>{" "}
                  <span>
                    <span>{details?.eventvalue.event_start_time}</span> -{" "}
                    <span>{details?.eventvalue.event_end_time}</span>
                  </span>
                </h6>
              </div>

              <div className="d-flex justify-content-start align-items-center text-dark mb-3">
                <Icon.GeoAlt
                  className="me-3"
                  size={35}
                  style={{ color: "#e5851f" }}
                />
                <h6>
                  <strong> Venue :- </strong>
                  <span
                    style={{
                      textDecoration: "underline solid blue",
                      color: "blue",
                    }}
                    onClick={() => {
                      showInMapClicked();
                    }}
                  >
                    {" "}
                    {details?.eventvalue.event_venue}
                  </span>
                </h6>
              </div>

              {details.amount > 0 ? (
                <div className="d-flex justify-content-start align-items-center text-dark mb-3">
                  <Icon.CurrencyRupee
                    className="me-3"
                    size={25}
                    style={{ color: "#e5851f" }}
                  />
                  <h6>
                    <strong>Registration Amount :- </strong>{" "}
                    {details?.amount > 0 ? details?.amount : ""}
                  </h6>
                </div>
              ) : (
                ""
              )}

              <div className="d-flex flex-column justify-content-center align-items-start text-dark mb-3">
                <div className="d-flex">
                  <Icon.CardText
                    className="me-3"
                    size={25}
                    style={{ color: "#e5851f" }}
                  />
                  <h6>
                    <strong> Description :- </strong>
                  </h6>
                </div>

                <p className="ps-5 mt-2">
                  {details?.eventvalue.event_description}
                </p>
              </div>

              {/* <h5 style={{ color: 'black' }}><Icon.JournalBookmarkFill className="me-3" size={25} style={{ color: '#e5851f' }} /><strong>View Brouchures</strong></h5> */}
              <a
                href={
                  details.eventvalue
                    ? details.eventvalue?.event_documents[0]?.url
                    : null
                }
                target="_blank"
              >
                {/* <div className="box-pdf w-100 mt-3 mb-3">
                  <AiOutlineFilePdf
                    style={{ color: "red", fontSize: "35px" }}
                  />{" "}
                  View Brouchures Pdf{" "}
                </div> */}
              </a>
              {details?.eventvalue?.event_youtube_video != "" ? (
                <div className="box-pdf w-100 mt-3 mb-3">
                  <h5 style={{ color: "black", marginBottom: "10px" }}>
                    Youtube Video
                  </h5>

                  <LiteYouTubeEmbed
                    id={`${id}`} // Default none, id of the video or playlist
                    adNetwork={true} // Default true, to preconnect or not to doubleclick addresses called by YouTube iframe (the adnetwork from Google)
                    params={`${text}`} // any params you want to pass to the URL, assume we already had '&' and pass your parameters string
                    playlist={false} // Use  true when your ID be from a playlist
                    playlistCoverId={`${id}`} // The ids for playlists did not bring the cover in a pattern to render so you'll need pick up a video from the playlist (or in fact, whatever id) and use to render the cover. There's a programmatic way to get the cover from YouTube API v3 but the aim of this component is do not make any another call and reduce requests and bandwidth usage as much as possibe
                    poster="hqdefault" // Defines the image size to call on first render as poster image. Possible values are "default","mqdefault",  "hqdefault", "sddefault" and "maxresdefault". Default value for this prop is "hqdefault". Please be aware that "sddefault" and "maxresdefault", high resolution images are not always avaialble for every video. See: https://stackoverflow.com/questions/2068344/how-do-i-get-a-youtube-video-thumbnail-from-the-youtube-api
                    title="YouTube Embed" // a11y, always provide a title for iFrames: https://dequeuniversity.com/tips/provide-iframe-titles Help the web be accessible ;)
                    noCookie={true} //Default false, connect to YouTube via the Privacy-Enhanced Mode using https://www.youtube-nocookie.com
                  />
                </div>
              ) : (
                ""
              )}

              <div className="text-center mt-2 w-100 ">
                {Event === details?.eventvalue.event_id ? (
                  <button className="btn btn-primary px-5" disabled>
                    Registered
                  </button>
                ) : (
                  <button
                    className="btn btn-primary px-5"
                    onClick={() => {
                      handlePayment();
                    }}
                  >
                    Register
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Eventdetails;
