import React, { useEffect, useState } from "react";
import "../pages/thankyou.css";
import logo from "../assets/images/logo.png";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment/moment";
import Yadnyalogo from "../assets/images/Yadnyalogo.webp";

const Thankyou = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [qrDetails, setQrDetails] = useState([]);

  const location = useLocation();
  const Member = location.state;
  const eventData = location.event;
  console.log("event Details", Member);

  console.log("Member Details from Register Event=>", Member);

  //  console.log("Event_id", Member.event_id);
  //  console.log("Mobile Number", Member.user.mobile)
  //  console.log("User Id", Member.user.user_id);
  const [QrImage, setQrImage] = useState(null);
  const [data, setData] = useState();
  const [pdf, setPdf] = useState();
  const [loader, setloader] = useState(false);
  const eventDetailss = useSelector((state) => state);
  console.log("event Details", eventDetailss);

  useEffect(() => {
    getMemberDetailss();
  }, []);

  // const getMemberDetails = async () => {
  //   console.log("In APi")
  //   const res = await fetch('https://goapi.foxberry.link/v1/eventpdf/pdf/get', {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json"
  //     },
  //     body: JSON.stringify({
  //       mobile: Member.data.user.mobile,
  //       event_id: Member.data.event_id,
  //     })
  //   }).then(res => res.json()).then(respon => {
  //     setData(respon[0].qr_code_link)
  //     setPdf(respon[0].pdf_link)
  //     console.log("Pramod", respon);

  //     if (res.status === 500 || !res) {
  //       window.alert("Invalid Registration");
  //       console.log("Invalid Registration");
  //     } else {
  //       console.log("gvdavdas", res)
  //       setQrDetails(`${res.url}`);
  //       console.log("Successful Registrationsss");
  //     }

  //   })
  //   // const data = await res.json();
  //   console.log("data", res.url);

  // }
  const getMemberDetailss = async () => {
    console.log("In APi");
    const res = await fetch(
      process.env.REACT_APP_API + "/eventpdf/create-pdf",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          mobile: Member.data.user.mobile,
          event_id: Member.data.event_id,
          first_name: Member.data.user.first_name,
          last_name: Member.data.user.last_name,
          user_id: Member.data.user.user_id,
        }),
      }
    )
      .then((res) => res.json())
      .then((respon) => {
        console.log("Pramod 1212", respon);
        setData(respon?.qr_code_link);
        setPdf(respon?.pdf_link);

        if (res.status === 500 || !res) {
          window.alert("Invalid Registration");
          console.log("Invalid Registration");
        } else {
          console.log("gvdavdas", res);
          setQrDetails(`${res.url}`);
          console.log("Successful Registrationsss");
        }
      });
    // const data = await res.json();
    console.log("data", res.url);
  };

  const downloadCert = (Link) => {
    setloader(true);
    fetch(Link)
      .then((response) => {
        setloader(false);
        response.blob().then((blob) => {
          // Creating new object of PDF file
          const fileURL = window.URL.createObjectURL(blob);
          // Setting various property values
          let alink = document.createElement("a");
          alink.href = fileURL;
          alink.download = "sample.pdf";
          alink.click();
        });
      })
      .catch((err) => {
        setloader(false);
        console.log("error", err);
      });
  };

  return (
    <div style={{ alignItems: "center" }}>
      <div className="header d-flex align-items-center w-100">
        <button
          onClick={() => {
            navigate(-1);
          }}
          className="btn btn-link text-white pl-0"
        >
          <i className="las la-arrow-left"></i>
        </button>
        <h2>Event Receipt</h2>
      </div>
      <div
        className="wrapper-1"
        style={{ display: "flex", alignItems: "center" }}
      >
        <div className="wrapper-2" data-aos="fade-up">
          <img src={Yadnyalogo} />
          <h1>Thank you !</h1>
          <p>
            {Member.data.user.first_name} {Member.data.user.last_name}
          </p>
          <br />
          <div>
            {data ? (
              <img src={data} width={100} alt="QR Code" />
            ) : (
              <div
                class="spinner-border text-primary "
                role="status"
                style={{ margin: "300px" }}
              ></div>
            )}

            <div style={{ marginTop: "25px" }}>
              <p>Event Name :{Member.event.eventvalue.event_title}</p>
              <p>Unique Id :{Member.event.eventvalue.event_title}</p>
              <p>Venue:{Member.event.eventvalue.event_venue}</p>
              <p>
                Date:
                {moment(Member.event.eventvalue.event_date).format(
                  "DD-MMM-YYYY "
                )}{" "}
                to{" "}
                {moment(Member.event.eventvalue.event_end_date).format(
                  "DD-MMM-YYYY "
                )}{" "}
              </p>
              <p>
                Time:
                {Member.event.eventvalue.event_start_time} to{" "}
                {Member.event.eventvalue.event_end_time}
              </p>
            </div>
          </div>
          {loader ? (
            <div
              class="spinner-border text-primary "
              role="status"
              style={{ margin: "300px" }}
            ></div>
          ) : (
            <button
              className="btn mt-5"
              onClick={() => {
                downloadCert(pdf);
              }}
            >
              Download Receipt
            </button>
          )}
        </div>
      </div>

      {/* <link href="https://fonts.googleapis.com/css?family=Kaushan+Script|Source+Sans+Pro" rel="stylesheet"></link> */}
    </div>
  );
};

export default Thankyou;
