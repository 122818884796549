import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Modal } from "react-bootstrap";
import dateFormat from "dateformat";
import SliderImage from "../assets/images/issue-details-image.png";
import EventImage1 from "../assets/images/event-image-1.png";
import EventImage2 from "../assets/images/event-image-2.png";
import axios from "axios";
import { useSelector } from "react-redux";
import useRazorpay from "react-razorpay";
import { AiOutlineFilePdf } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";
// import EventImage1 from "../assets/images/event-image-1.png";
// import logo33 from "../assets/images/";
import YoutubeEmbed from "./YoutubeEmbed";
import { wait } from "@testing-library/user-event/dist/utils";
import moment from "moment";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
function Subevent(props) {
  const [isEventList, setIsEventData] = useState(false);
  const [eventList, setAllEventData] = useState([]);
  const [show, setShow] = useState(false);
  const [eventDetails, setEventDetails] = useState();
  const [isEventDetails, setIsEventDetails] = useState(false);
  const handleClose = () => setShow(false);
  const params = useParams();
  const [imageSlider, setImageSlider] = useState(SliderImage);
  const [details, setdetails] = useState({
    amount: "",
    startpayment: false,
    eventvalue: "",
  });
  const handleShow = () => {
    setShow(true);
  };

  //For geting state value
  const userdetails = useSelector((state) => state.auth.userDetails);
  // console.log("userdetails", userdetails);
  //rezor pay
  const Razorpay = useRazorpay();
  //assign variable to navigate
  const navigate = useNavigate();
  //Add member in evevnt
  const AddMemberinvent = async () => {
    const event_id = params.event_id;
    const member = userdetails;
    const data = {
      event_id,
      member,
    };
    const res = await axios.post(
      process.env.REACT_APP_API + "/registerevent/add/event/member",
      data
    );
    return res;
  };
  useEffect(() => {
    getEventData();
    subeventList();
  }, []);
  //if user is not logged in navigate to home
  const getEventData = async () => {
    axios
      .get(
        process.env.REACT_APP_API + "/event/list/event_id/" + params.event_id
      )
      .then((response) => {
        console.log("-------------------EVENT DATA ---- : ", response.data[0]);
        setEventDetails(response.data);
        setIsEventDetails(true);
        console.warn(
          "response.data[0].event_images[0].url",
          response.data[0].event_images[0].url
        );
        setImageSlider(response.data[0].event_images[0].url);
        setdetails({
          ...details,
          amount: response.data[0]?.event_amount,
          eventvalue: response.data[0],
        });
      })
      .catch((error) => {
        console.log("url response in error-----", error);
      });
  };
  const subeventList = async () => {
    setIsEventData(true);

    axios
      .post(process.env.REACT_APP_API + "/event/get/event/with/event/head/id", {
        event_id: params.event_id,
      })
      .then((response) => {
        console.log("-------------------Subevent list ---- : ", response.data);
        setAllEventData(response.data[0].SubEvents);
        setIsEventData(false);
      })
      .catch((error) => {
        console.log("url response in subevent list-----", error);
      });
  };

  const addedToCalender = () => {
    let data = {
      yin_id: localStorage.getItem("yin_id"),
      title: eventDetails[0].event_title,
      start_date: eventDetails[0].event_date,
      end_date: eventDetails[0].event_date,
      start_time: eventDetails[0].event_start_time,
      end_time: eventDetails[0].event_end_time,
      entity_id: eventDetails[0].event_id,
      entity_link: eventDetails[0].event_link,
      entity_status: "PENDING",
    };
    axios
      .post(process.env.REACT_APP_TALK_API + "/calender/create", data)
      .then((response) => {
        console.log(
          "-------------------DATA ADDED IN CALENDER ---- : ",
          response
        );
        handleClose();
      })
      .catch((error) => {
        console.log("url response in error-----", error);
      });
  };
  //handle payment
  const notify = () =>
    toast("Please! Login First", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  const notify1 = () =>
    toast("Member Registered successfully", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  const handlePayment = async () => {
    if (userdetails == null) {
      notify();
      setTimeout(() => {
        navigate("/login");
      }, 2100);
    } else {
      if (details.amount == 0) {
        await AddMemberinvent().then((res) => {
          if (res?.data) {
            notify1();
          }
        });
      } else {
        const order = await createOrder(); //  Create order on your backend

        const transactionDetails = {
          amount: Number(details.amount) * 100,
          event_id: "GOA_FORUM_2023", //Event ID
          order_id: order.id,
          mobile: details.mobile,
          email: details.email ?? "younginspiratorsnetwork@gmail.com",
          type: "LIVE",
          platform: "MOBILE",
        };
        await handlePaymentInformationSync(transactionDetails, "SAVE");

        const options = {
          key: process.env.REACT_APP_REZORPAY_TEST, // Enter the Key ID generated from the Dashboard
          amount: details.amount * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
          currency: "INR",
          name: "GOA FORUM",
          description: "Test Transaction",
          image: "https://example.com/your_logo",
          //order_id: "order_9A33XWu170gUtm", //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
          handler: async function (response) {
            console.warn(
              "response.razorpay_payment_id",
              response.razorpay_payment_id
            );
            console.warn(
              "response.razorpay_order_id",
              response.razorpay_order_id
            );
            console.warn(
              "response.razorpay_signature",
              response.razorpay_signature
            );
            const transaction_id = response.razorpay_payment_id;
            console.warn("transaction", response);
            if (!response.razorpay_payment_id) {
              // Transaction possibly failed
              // Fetch payments of user and compare with event_id?
              await handlePaymentInformationSync(
                {
                  transaction_id,
                  email: details.email,
                  userName: details.first_name,
                  type: "DEV",
                  mobile: details.mobile,
                  event_id: "GOA_FORUM_2023",
                  orderStatus: "FAILED",
                },
                "UPDATE"
              );
            } else {
              // Transaction was success
              // Create a payment package with all details and move to payment page,
              // Give page name to return to after payment
              const transaction_id = response.razorpay_payment_id;
              // const rzpPaymentResponse = await eventApi.getPaymentStatus(paymentId);
              //// console.log('Payment status-----', paymentId);
              await handlePaymentInformationSync(
                {
                  transaction_id,
                  email: userdetails.email,
                  userName: userdetails.first_name,
                  type: "DEV",
                  mobile: userdetails.mobile,
                  event_id: "GOA_FORUM_2023",
                  orderStatus: "SUCCESS",
                },
                "UPDATE"
              );
            }
            await AddMemberinvent().then((res) => {
              if (res?.data) {
                notify1();
              }
            });
          },
          prefill: {
            name: userdetails?.first_name,
            email: userdetails?.email,
            contact: userdetails?.mobile,
          },
          notes: {
            address: userdetails.currentAddressLine1,
          },
          theme: {
            color: "#3399cc",
          },
        };

        const rzp1 = new Razorpay(options);

        rzp1.on("payment.failed", function (response) {
          alert(response.error.code);
          alert(response.error.description);
          alert(response.error.source);
          alert(response.error.step);
          alert(response.error.reason);
          alert(response.error.metadata.order_id);
          alert(response.error.metadata.payment_id);
        });

        rzp1.open();
      }
    }
  };

  async function createOrder() {
    let orderDetails = {
      amount: Number(details?.amount) * 100,
      currency: "INR",
      receipt: `MOBILE_PAY_${Date.now().toString()}`,
    };
    try {
      const createOrderResponse = await axios.post(
        process.env.REACT_APP_RAZORPAYAPI + "/orders",
        orderDetails,
        {
          auth: process.env.REACT_APP_REZORPAY_TEST,
        }
      );

      return createOrderResponse;
    } catch (error) {
      return error;
    }
  }

  async function handlePaymentInformationSync(t, i) {
    try {
      const response = await axios.post(
        "https://yin-api.foxberry.link/v2/payments/transactions",
        t,
        i
      );

      console.log(
        "--------- handlePaymentInformationSync --------------------------",
        response
      );
      // onRefresh();
    } catch (error) {
      console.warn(error);
    }
  }
  // if (details.startpayment) {
  //   return (
  //     <div
  //       style={{
  //         flex: 1,
  //         display: "flex",
  //         alignItems: "center",
  //         justifyContent: "center",
  //       }}
  //     >
  //       <div class="spinner-border text-primary" role="status">
  //         <span class="sr-only">Loading...</span>
  //       </div>
  //     </div>
  //   );
  // }
  const text = details.eventvalue.event_youtube_video;

  const id = details.eventvalue
    ? text.slice(details.eventvalue.event_youtube_video.indexOf("=") + 1)
    : "";

  return (
    <div
      style={{
        backgroundColor: "#F0F0F0",
        paddingLeft: "10px",
        paddingRight: "10px",
      }}
    >
      <div className="header">
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        {/* Same as */}
        <ToastContainer />
        <div className="d-flex align-items-center w-100">
          <button
            onClick={() => {
              navigate(-1);
            }}
            className="btn btn-link text-white pl-0"
          >
            <i className="las la-arrow-left"></i>
          </button>
          <h2>Subevent</h2>
        </div>

        <div
          className="slider-area"
          style={{
            backgroundImage: `url({logo333})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
          }}
        ></div>
      </div>
      {eventList.length == 0 ? (
        <div className="box  w-100 ">
          <div
            className="slider-area"
            style={{
              backgroundImage: "url(" + imageSlider + ")",
              backgroundRepeat: "no-repeat",
              backgroundSize: "100% 100%",
            }}
          ></div>
          <div>
            <span>
              {moment(details.eventvalue.event_start_date).format(
                "DD-MMM-YYYY "
              )}
            </span>
            {"  "}
            <span>
              {moment(details.eventvalue.event_end_date).format("DD-MMM-YYYY")}
            </span>
            {"  "}
            <span>{details.eventvalue.event_start_time}</span> |
            <span>{details.eventvalue.event_end_time}</span>
          </div>
          <h2>{details.eventvalue.event_title}</h2>
          <h6>
            Event Venue :<Link to={"/"}></Link>
          </h6>
          <h6>Registration Amount {details.amount}</h6>
          <h5>Event Description</h5>
          <p>{details.eventvalue.event_full_description}</p>
          <div>
            <h5>View Brouchures</h5>

            <a
              href={
                eventDetails ? eventDetails[0]?.event_documents[0]?.url : null
              }
              target="_blank"
            >
              <div className="box-pdf w-100 mt-3 mb-3">
                <AiOutlineFilePdf style={{ color: "red", fontSize: "35px" }} />{" "}
                View Brouchures Pdf{" "}
              </div>
            </a>
          </div>
          {details.eventvalue?.event_youtube_video !==
          "EVENT_YOUTUBE_VIDEO_NOT_ADDED" ? (
            <div className="box-pdf w-100 mt-3 mb-3">
              <h5>Youtube Video</h5>

              <LiteYouTubeEmbed
                id={`${id}`} // Default none, id of the video or playlist
                adNetwork={true} // Default true, to preconnect or not to doubleclick addresses called by YouTube iframe (the adnetwork from Google)
                params={`${text}`} // any params you want to pass to the URL, assume we already had '&' and pass your parameters string
                playlist={false} // Use  true when your ID be from a playlist
                playlistCoverId={`${id}`} // The ids for playlists did not bring the cover in a pattern to render so you'll need pick up a video from the playlist (or in fact, whatever id) and use to render the cover. There's a programmatic way to get the cover from YouTube API v3 but the aim of this component is do not make any another call and reduce requests and bandwidth usage as much as possibe
                poster="hqdefault" // Defines the image size to call on first render as poster image. Possible values are "default","mqdefault",  "hqdefault", "sddefault" and "maxresdefault". Default value for this prop is "hqdefault". Please be aware that "sddefault" and "maxresdefault", high resolution images are not always avaialble for every video. See: https://stackoverflow.com/questions/2068344/how-do-i-get-a-youtube-video-thumbnail-from-the-youtube-api
                title="YouTube Embed" // a11y, always provide a title for iFrames: https://dequeuniversity.com/tips/provide-iframe-titles Help the web be accessible ;)
                noCookie={true} //Default false, connect to YouTube via the Privacy-Enhanced Mode using https://www.youtube-nocookie.com
              />
            </div>
          ) : (
            ""
          )}

          {/* <Carousel>
            <div>
              <img src="assets/1.jpeg" />
              <p className="legend">Legend 1</p>
            </div>
            <div>
              <img src="assets/2.jpeg" />
              <p className="legend">Legend 2</p>
            </div>
            <div>
              <img src="assets/3.jpeg" />
              <p className="legend">Legend 3</p>
            </div>
          </Carousel> */}
          <div className="text-center mt-2">
            <button
              className="btn btn-primary px-5"
              onClick={() => {
                handlePayment();
              }}
            >
              Register for Events
            </button>
          </div>
        </div>
      ) : (
        ""
      )}
      {eventList.length !== 0 ? (
        <div className="text-center m-2">
          {" "}
          <h2>{details.eventvalue.event_title}</h2>
        </div>
      ) : (
        ""
      )}
      <div className="row events-container">
        {isEventList ? (
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div class="spinner-border text-primary mt-5" role="status">
              {/* <span class="sr-only">Loading...</span> */}
            </div>
          </div>
        ) : (
          eventList.map((event, index) => {
            return (
              <div className="col-sm-6 col-12" key={index}>
                <div className="event-block clearfix">
                  <div className="p-2 text-left">
                    <h2 className="mb-1">
                      {event.event_title.length > 30
                        ? `${event.event_title.slice(0, 30)}...`
                        : event.event_title}
                    </h2>
                    <p className="time mb-2">
                      {dateFormat(event.event_date, "dS mmm yyyy")} |{" "}
                      {event.event_start_time} to {event.event_end_time}
                    </p>
                    {/* <button
                          type="button"
                          className="btn btn-link btn-calender text-blue"
                          onClick={() => handleShow(event)}
                        >
                          <i className="las la-calendar"></i>
                        </button> */}
                  </div>
                  <figure>
                    <img
                      src={
                        event.event_images
                          ? event.event_images[0]?.url
                          : EventImage1
                      }
                      alt="EventImage1"
                    />
                  </figure>
                  {/* <div className="p-2">
                        <p className="event-desc truncate truncate-2 mb-1">
                          {event.event_description}
                        </p>
                      </div> */}
                  <div className="text-center mt-2">
                    <button
                      onClick={() => {
                        navigate("/user/eventdetails/" + event.event_id);
                      }}
                      className="btn btn-primary px-5"
                    >
                      View Details
                    </button>
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
}

export default Subevent;
